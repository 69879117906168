      // When the user scrolls the page, execute myFunction
      window.onscroll = function () {
        myFunction();
      };

      function myFunction() {
        var winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        var height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("scrollIndicator").style.width = scrolled + "%";
      }

const swiper = new Swiper('.solutions__swiper .swiper', {
    // Default parameters
    slidesPerView: 1,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    // Responsive breakpoints
  })
  
$(document).on('click','.header__mobile__item__icon i',function(){
    var $this = $(this);
    if($('.header__mobile__item__menu').hasClass('active'))
    {
        $('.header__mobile__item__menu').slideUp(200).removeClass('active')
        $this.removeClass('pr-icons8-cancel').addClass('pr-icons8-menu-48');

    }
    else
    {
        $('.header__mobile__item__menu').slideDown(200).addClass('active')
        $this.removeClass('pr-icons8-menu-48').addClass('pr-icons8-cancel');
    }
    

})